import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class CampaignService {
  private readonly apiPrefix = '/client/campaigns';

  constructor(
    private api: ApiService
  ) {
  }

  getList(qs = '') {
    return this.api.get(`${this.apiPrefix}${qs}`);
  }

  connectToProvider(id: number) {
    return this.api.post(`${this.apiPrefix}/${id}/provider-connection`, {});
  }
}
